.hero {
  width: calc(100% - 32px);
  max-width: 1720px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
}
.heroImg {
  position: absolute;
  top: 240px;
  height: 306px;
  z-index: -1;
  aspect-ratio: 1/1;
}
.textContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: start;
}
.textContent > h1 {
  color: rgba(19, 20, 17, 0.72);
  font-family: 'Konstant Grotesk Book', 'serif';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 88%;
  text-transform: uppercase;
}
.textContent > h2 {
  color: rgba(19, 20, 17, 0.6);
  font-family: 'Konstant Grotesk Book', 'serif';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 96%;
  text-transform: uppercase;
}

.services {
  display: flex;
  gap: 24px;
  width: 288px;
  margin-top: 382px;
}

@media screen and (min-width: 700px) {
  .textContent > h1 {
    font-size: 96px;
  }
  .textContent > h2 {
    font-size: 48px;
  }
  .services {
    margin-top: 276px;
  }
  .hero {
    padding-top: 86px;
    width: calc(100% - 80px);
  }
  .heroImg {
    height: 422px;
    top: 200px;
    left: 20dvw;
  }
}

@media screen and (min-width: 1280px) {
  .textContent > h1 {
    font-size: 128px;
  }
  .services {
    margin-top: 100px;
    align-self: end;
  }
  .textContent > h2 {
    font-size: 64px;
  }
  .hero {
    width: calc(100% - 200px);
    padding-top: 160px;
  }
  .heroImg {
    height: 620px;
    top: 50px;
    left: 400px;
  }
}
@media screen and (min-width: 1820px) {
  .hero {
    padding-top: 200px;
  }
  .textContent > h1 {
    font-size: 180px;
  }
  .textContent > h2 {
    font-size: 90px;
  }
  .services {
    margin-top: 33px;
  }
  .heroImg {
    top: 0;
    left: 600px;
    height: 830px;
  }
}
