*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  font-family: 'Inter', serif;
  overflow-x: hidden;
  background: #ebebeb;
}
@font-face {
  font-family: 'Konstant Grotesk Book';
  font-style: normal;
  font-weight: normal;
  src:
    local('Konstant Grotesk Book'),
    url('./assets/neuekonstantgrotesk-book.woff') format('woff');
}
