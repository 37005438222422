.policyWrapper {
  width: calc(100% - 32px);
  max-width: 1720px;
  margin-block: 50px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.policyWrapper > h1 {
  color: rgba(19, 20, 17, 0.72);
  font-family: 'Konstant Grotesk Book', 'serif';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 112%;
  text-transform: uppercase;
}
.policyContent {
  padding-block: 24px;
}
.policyContent > p {
  color: rgba(19, 20, 17, 0.72);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
@media screen and (min-width: 700px) {
  .policyWrapper {
    width: calc(100% - 80px);
    margin-top: 76px;
    margin-bottom: 100px;
  }
  .policyWrapper > h1 {
    font-size: 64px;
  }
  .policyContent {
    border-top: 1px solid rgba(116, 116, 116, 0.24);
    border-bottom: 1px solid rgba(116, 116, 116, 0.24);
  }
  .policyContent > p {
    font-size: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .policyWrapper {
    width: calc(100% - 200px);
    margin-top: 40px;
  }
  .policyWrapper > h1 {
    font-size: 80px;
  }
  .policyContent > p {
    font-size: 20px;
  }
  .policyContent {
    padding-block: 48px;
  }
}
@media screen and (min-width: 1820px) {
  .policyWrapper {
    width: calc(100% - 200px);
    margin-top: 56px;
  }
  .policyWrapper > h1 {
    font-size: 112px;
  }
  .policyContent > p {
    font-size: 24px;
  }
}
