.header {
  width: calc(100% - 32px);
  max-width: 1720px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.header > img {
  z-index: 3;
}
.burgerButton {
  cursor: pointer;
}
.lockScroll {
  overflow-y: hidden;
}
.navbar {
  display: none;
  padding: 16px 16px 0 16px;
}
.navbarContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  padding: 16px;
  margin-top: 70px;
}
.navbarContent > a {
  color: rgba(19, 20, 17, 0.64);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%;
  transition: color 0.3s;
}
.navbar.burgerNavbar {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 0;
  top: 0;
  left: 0;
  background: #ebebeb;
  z-index: 2;
}
.burgerImage {
  position: absolute;
  top: 280px;
  left: 20%;
  width: 64dvw;
  z-index: -1;
  aspect-ratio: 1/1;
}
@media (hover: hover) {
  .navbarContent a:hover {
    color: #09158b;
  }
}
@media screen and (min-width: 500px) {
  .burgerNavbar .navbarContent > a {
    font-size: 40px;
  }
}
@media screen and (min-width: 700px) {
  .header {
    width: calc(100% - 80px);
    margin-top: 40px;
  }
  .navbar {
    padding: 40px 40px 0 40px;
  }
}
@media screen and (min-width: 745px) {
  .burgerButton {
    display: none;
  }
  .navbar {
    display: block;
    padding: 0;
  }
  .navbarContent {
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .navbar.burgerNavbar {
    display: block;
    padding: 0;
    position: static;
    width: auto;
    height: auto;
  }
  .burgerImage {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .header {
    width: calc(100% - 200px);
    margin-top: 80px;
  }

  .navbarContent > a {
    font-size: 32px;
  }
}
@media screen and (min-width: 1820px) {
  .navbarContent > a {
    font-size: 44px;
  }
}
