.aboutUsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 20px;
  margin-bottom: 64px;
}

@media screen and (min-width: 1280px) {
  .cardsContainer {
    flex-direction: row;
    gap: 80px;
    margin-top: 40px;
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1820px) {
  .cardsContainer {
    flex-direction: row;
    gap: 104px;
    margin-bottom: 120px;
  }
}
