.form {
  width: 100%;
  max-width: 860px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 22px;
}
.formContent {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.contactBlock h3 {
  color: rgba(19, 20, 17, 0.8);

  font-family: 'Konstant Grotesk Book', 'serif';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.contactBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.contactImg {
  position: absolute;
  bottom: 40px;
  left: 30%;
  z-index: -1;
  height: 170px;
  aspect-ratio: 1/1;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
}
.socialContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.socialItems {
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  align-items: flex-start;
  align-self: start;
}
.socialMediaItem {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.socialMediaItem > p {
  color: rgba(19, 20, 17, 0.64);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.wrapper {
  width: calc(100% - 32px);
  max-width: 1720px;
  margin-top: 64px;
  margin-bottom: 58px;
}
.contactBlock hr {
  display: none;
  height: 1px;
  background: rgba(19, 20, 17, 0.16);
}
@media screen and (min-width: 700px) {
  .wrapper {
    width: calc(100% - 80px);
    margin-bottom: 86px;
  }
  .contentWrapper {
    gap: 66px;
  }
  .contactBlock {
    padding-bottom: 50px;
    gap: 10px;
  }
  .contactBlock h3 {
    font-size: 32px;
  }
  .form br {
    display: none;
  }
  .contactImg {
    position: absolute;
    bottom: 0;
    height: 290px;
  }
  .contactBlock h3 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1280px) {
  .contactBlock hr {
    display: block;
    height: 1px;
    background: rgba(19, 20, 17, 0.16);
  }
  .wrapper {
    width: calc(100% - 200px);
    margin-top: 88px;
    margin-bottom: 0;
  }
  .contentWrapper {
    width: 93%;
    display: flex;
    flex-direction: row;
    gap: 260px;
    justify-content: space-between;
  }
  .socialContent {
    min-width: fit-content;
  }
  .form {
    min-width: 550px;
  }
  .contentWrapper {
    padding-top: 66px;
    padding-bottom: 173px;
  }
  .contactImg {
    position: absolute;
    top: 200px;
    left: 40%;
    height: 260px;
  }
  .contactBlock {
    padding-bottom: 0;
  }
  .socialMediaItem > p {
    font-size: 20px;
  }
}
@media screen and (min-width: 1820px) {
  .contactImg {
    top: 250px;
  }
  .contentWrapper {
    padding-top: 80px;
    padding-bottom: 265px;
  }
  .contactBlock h3 {
    font-size: 40px;
  }
}
