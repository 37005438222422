.button {
  width: 100%;
  height: 40px;
  border-radius: 16px;
  background: rgba(9, 21, 139, 0.4);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
