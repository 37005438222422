.layout {
  width: 100dvw;
  min-height: 100dvh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.whiteLayout {
  background: #fff;
}
