.sectionHeader {
  color: rgba(19, 20, 17, 0.72);
  font-family: 'Konstant Grotesk Book', 'serif';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
@media screen and (min-width: 700px) {
  .sectionHeader {
    font-size: 64px;
  }
}
@media screen and (min-width: 1280px) {
  .sectionHeader {
    font-size: 80px;
  }
}
@media screen and (min-width: 1820px) {
  .sectionHeader {
    font-size: 112px;
  }
}
