.wrapper {
  width: calc(100% - 32px);
  max-width: 1720px;
  margin-block: 64px;
}
@media screen and (min-width: 700px) {
  .wrapper {
    width: calc(100% - 80px);
    margin-bottom: 72px;
  }
}

@media screen and (min-width: 1280px) {
  .wrapper {
    width: calc(100% - 200px);
    margin-top: 88px;
    margin-bottom: 100px;
  }
}
