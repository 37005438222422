.footer {
  display: none;
}
.footer.burgerFooter {
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
  margin-top: auto;
}
.footer > p {
  width: 50%;
  color: rgba(19, 20, 17, 0.8);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
}
.burgerFooter .socialMedia {
  margin-top: 48px;
}
.socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.links {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
}
.burgerFooter .links {
  align-self: end;
  align-items: end;
  padding-inline: 16px;
  flex-direction: column;
  gap: 24px;
  font-size: 18px;
  order: -1;
}
.burgerFooter > p {
  order: 1;
  align-self: center;
  text-align: center;
  margin-top: 28px;
}
.links > a {
  color: rgba(19, 20, 17, 0.64);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%;
}
@media screen and (min-width: 500px) {
  .burgerFooter .links > a {
    font-size: 24px;
  }
}
@media screen and (min-width: 745px) {
  .footer.burgerFooter {
    display: none;
  }
  .footer {
    width: calc(100% - 80px);
    max-width: 1720px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 40px;
  }
  .socialMedia {
    gap: 20px;
  }
  .links {
    gap: 20px;
  }
  .links > a {
    font-size: 16px;
  }
}
@media screen and (min-width: 1050px) {
  .socialMedia {
    gap: 48px;
  }
  .links {
    gap: 40px;
  }
  .links > a {
    font-size: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .footer {
    width: calc(100% - 200px);
  }
}
@media screen and (min-width: 1441px) {
  .footer > p {
    font-size: 24px;
  }
  .socialMedia {
    gap: 66px;
  }
  .socialMedia > a > img {
    width: 38px;
    height: 38px;
  }
  .links > a {
    font-size: 24px;
  }
  .links {
    gap: 48px;
  }
}
