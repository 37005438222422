.textCard {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-top: 1px solid rgba(116, 116, 116, 0.24);
  padding-block: 24px;
}
.textCard > h3 {
  color: rgba(19, 20, 17, 0.88);
  font-family: 'Konstant Grotesk Book', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 112%;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.textCard > p {
  color: rgba(19, 20, 17, 0.88);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.lastCard {
  border-bottom: 1px solid rgba(116, 116, 116, 0.24);
}

@media screen and (min-width: 700px) {
  .textCard > h3 {
    font-size: 32px;
  }
  .textCard > p {
    font-size: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .textCard > h3 {
    font-size: 40px;
  }
  .textCard > p {
    font-size: 20px;
    max-width: 554px;
  }
  .textCard {
    padding-right: 66px;
  }
  .textCard:not(:last-child) {
    padding-bottom: 60px;
  }
  .textCard:last-child {
    padding-bottom: 33px;
  }
  .textCard.aboutUsTextCard {
    flex-direction: row;
    justify-content: space-between;
  }
  .textCard.aboutUsTextCard > h3 {
    max-width: min-content;
  }
  .textCard.aboutUsTextCard > p {
    max-width: 620px;
  }
}

@media screen and (min-width: 1600px) {
  .textCard.aboutUsTextCard > p {
    max-width: 860px;
  }
}

@media screen and (min-width: 1820px) {
  .textCard > h3 {
    font-size: 56px;
  }
  .textCard {
    padding-right: 208px;
  }
  .textCard > p {
    font-size: 24px;
    max-width: 674px;
  }
  .textCard:not(:last-child) {
    padding-bottom: 60px;
  }
  .textCard:last-child {
    padding-bottom: 32px;
  }
}
