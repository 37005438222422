.informationBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.content > img {
  align-self: end;
  width: 160px;
  height: 155px;
}
.wrapper {
  width: calc(100% - 32px);
  max-width: 1720px;
  margin-top: 116px;
  margin-bottom: 64px;
}

@media screen and (min-width: 700px) {
  .wrapper {
    width: calc(100% - 80px);
    margin-bottom: 72px;
  }
  .content > img {
    align-self: end;
    width: 240px;
    height: 232px;
    margin-right: 60px;
  }
}
@media screen and (min-width: 1280px) {
  .wrapper {
    width: calc(100% - 200px);
    margin-top: 188px;
    margin-bottom: 100px;
  }
  .informationBlock {
    gap: 52px;
  }
  .content > img {
    align-self: end;
    width: 325px;
    height: 318px;
    margin-right: 0;
  }
  .content {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1820px) {
  .informationBlock {
    gap: 92px;
  }
  .content > img {
    align-self: end;
    width: 455px;
    height: 445px;
    margin-right: 0;
  }
  .content {
    flex-direction: row;
    gap: 405px;
  }
  .wrapper {
    width: calc(100% - 200px);
    margin-top: 214px;
  }
}
