.aboutUsCard {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 160px;
}
.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.textContent > p {
  max-width: 272px;
}
.textContent > h2 {
  color: rgba(19, 20, 17, 0.72);
  font-family: 'Konstant Grotesk Book', 'serif';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 112%;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.textContent > p {
  text-align: center;
  color: rgba(19, 20, 17, 0.88);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
@media screen and (min-width: 1280px) {
  .aboutUsCard {
    height: 320px;
  }
  .textContent {
    margin-top: 92px;
    margin-bottom: auto;
  }
  .textContent > p {
    max-width: 288px;
  }
  .textContent > h2 {
    font-size: 40px;
  }
  .textContent > p {
    font-size: 20px;
  }
  .aboutUsCard {
    border-radius: 32px;
  }
}
@media screen and (min-width: 1820px) {
  .aboutUsCard {
    height: 448px;
  }
  .textContent > h2 {
    font-size: 56px;
  }
  .textContent > p {
    font-size: 24px;
  }
  .textContent {
    margin-top: 128px;
  }
}
