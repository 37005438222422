.input {
  width: 100%;
  color: rgba(19, 20, 17, 0.64);
  background: transparent;
  padding-left: 12px;
  height: 40px;
  border-radius: 16px;
  border: 1px solid rgba(19, 20, 17, 0.64);
}
.input::placeholder {
  color: rgba(19, 20, 17, 0.4);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
